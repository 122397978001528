<template lang="pug">
.invite-window
      .invite-border
      .diamand
      .iv-image
        .background
        .iv-title
          <span>{{ $t('inviteWindow.titleLabel') }}</span>
        .iv-subtitle
          <span class="text-h5">{{ $t('inviteWindow.text1Label') }} {{ getBrand.name }}, {{ $t('inviteWindow.text2Label') }} <a href="bonus-policy">{{ $t('inviteWindow.moreLink') }}</a></span>
        .iv-btns
          .signup(@click.stop="doLogin") {{ $t('headerNew.signupButton') }}
//-            .iv-col
//-                <div>Instant deposits<br/>and withdrawals</div>
//-            .iv-col
//-                <div>Live support<br/>24/7</div>
//-            .iv-col
//-                <div><img :src="require('@/assets/images/invite-window/visa_master.svg')" />and many more...</div>

</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    name: 'InviteWindow',
    computed: {
      ...mapGetters({
        getBrand: 'main/getBrand',
      }),
    },
    methods: {
        ...mapMutations({
          SET_SIGNUP_VISIBLE: 'auth/SET_SIGNUP_VISIBLE'
        }),

        async doLogin() {
            this.SET_SIGNUP_VISIBLE(true);
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/styles/top-menu/sunduk-white/banner/invite-window_sw.scss";
</style>